//
// Sidebar Minimize
//

.app-sidebar-logo-minimize {
  display: none;
}

// Sidebar footer
// TODO: aya tarif style inja best practice has ?
.app-sidebar-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}

// Desktop sidebar minimize mode
@include app-layout-minimize(app-sidebar) {
  .menu-link {
    padding: $app-sidebar-minimize-menu-link-padding-y $app-sidebar-minimize-menu-link-padding-x;
  }
  .app-sidebar-logo {
    .app-sidebar-logo-default {
      display: none;
    }

    .app-sidebar-logo-minimize {
      display: inline-block;
    }
  }

  .app-sidebar-wrapper {
    width: 100%;
  }

  .login-info-title-wrapper {
    justify-content: center;
    margin-bottom: 0 !important;
  }

  .app-sidebar-menu {
    .menu-content,
    .menu-title {
      opacity: 0;
      display: none;
      @include app-layout-transition(opacity);
    }
    .menu-icon {
      margin-right: 0
    }
    .menu-item.show > .menu-sub {
      height: 0;
      overflow: hidden;
      @include app-layout-transition(height);
    }
  }

  .app-sidebar-footer {
    button {
      opacity: 0;
      display: none;
      @include app-layout-transition(opacity); 
    }
  }
}
