//
// Shadow
//

.shadow-xs {
	box-shadow: var(--kt-box-shadow-xs);
}

.shadow-sm {
	box-shadow: var(--kt-box-shadow-sm);
}

.shadow {
	box-shadow: var(--kt-box-shadow);
}

.shadow-lg {
	box-shadow: var(--kt-box-shadow-lg);
}

.shadow-box {
	box-shadow: var(--kt-box-shadow-box);
}
