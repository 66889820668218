//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system
$white: #ffffff;

// Theme colors
// Primary
$primary: #e11f26;
$primary-active: #cb1b22;
$secondary: #0a0a0a; // #212121 #464E5F
$primary-light: #fbe4e5;
$primary-light-dark: #212e48;
$primary-inverse: $white;

// Success
$success: #00cb96;
$success-active: #47be7d;
$success-light: #e8fff3;
$success-light-dark: #1c3238;
$success-inverse: $white;

// Info
$info: #7239ea;
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #2f264f;
$info-inverse: $white;

// Danger
$danger: #f1416c;
$danger-active: #d9214e;
$danger-light: #fff5f8;
$danger-light-dark: #3a2434;
$danger-inverse: $white;


$gray-100: #f5f5f5; //#F3F6F9
$gray-200: #e5e8eb; // stroke
$gray-300: #cbcbcb; // shade 03
$gray-400: #a0a0a0; // #B5B5C3 // #A1A5B7 // #5E6278;
$gray-500: #4b4b4b; // shade 1

$gray-100-dark : #f5f5f5;
$gray-200-dark : #e5e8eb;
$gray-500-dark : #4b4b4b;


$dark: $gray-500;
$dark-dark: $gray-500-dark;
$dark-active: darken($gray-500, 3%);
$dark-active-dark: lighten($gray-500-dark, 3%);
$dark-light: $gray-200;
$dark-light-dark: $gray-200-dark;
$dark-inverse: $white;
$dark-inverse-dark: $gray-100-dark;




$gray-100-dark: #1b1b29;
$gray-200-dark: #2b2b40;
$gray-300-dark: #323248;
$gray-400-dark: #474761;
$gray-500-dark: #565674;

// Warning
$warning: #ffba00;
$warning-active: #f1bc00;
$warning-light: #fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: $white;

$light-inverse: $gray-500;
$light-inverse-dark: $gray-500-dark;

$box-shadow-box: 0.25rem 0.25rem 0.75rem 0px rgba(225, 224, 228, 0.3);