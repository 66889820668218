//
// Variables
//

// Reboot
$app-bg-color: #f3f6f9;
$app-bg-color-dark: #151521;
$app-blank-bg-color: $white;
$app-blank-bg-color-dark: $app-bg-color-dark;

// Header base
$app-header-base-height: 70px;
$app-header-base-height-mobile: 60px;
$app-header-base-bg-color: $white;
$app-header-base-bg-color-dark: #1e1e2d;
$app-header-base-bg-color-mobile: $app-header-base-bg-color;
$app-header-base-bg-color-mobile-dark: $app-header-base-bg-color-dark;
$app-header-base-box-shadow:none;
$app-header-base-box-shadow-dark: none;
$app-header-base-box-shadow-mobile: none;
$app-header-base-box-shadow-mobile-dark: none;

// TODO: unused????
$app-header-base-menu-link-bg-color-active: $menu-link-bg-color-active; 
$app-header-base-menu-link-bg-color-active-dark: #2a2a3c;

// Header light
$app-header-light-separator-color: transparent;
$app-header-light-separator-color-dark: transparent;

// Header dark
$app-header-dark-bg-color: #1e1e2d;
$app-header-dark-separator-color: #282a3d;
$app-header-dark-scrollbar-color: #3b3b64;
$app-header-dark-scrollbar-color-hover: lighten(
	$app-header-dark-scrollbar-color,
	3%
);

// Sidebar base
$app-sidebar-base-width: 16.5rem;
$app-sidebar-base-width-mobile: 3rem;
$app-sidebar-base-toggle-btn-box-shadow: 0px 0px 10px rgba(113, 121, 136, 0.4);
$app-sidebar-base-toggle-btn-box-shadow-dark: none;
$app-sidebar-base-toggle-btn-bg-color: $body-bg;
$app-sidebar-base-toggle-btn-bg-color-dark: $app-header-base-menu-link-bg-color-active-dark;

// Sidebar minimize
$app-sidebar-minimize-width: 5.75rem;

// Sidebar light
$app-sidebar-light-bg-color: $body-bg;
$app-sidebar-light-bg-color-dark: #1e1e2d;

$app-sidebar-light-box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.05);
$app-sidebar-light-box-shadow-dark: none;

$app-sidebar-light-separator-color: $app-header-light-separator-color;
$app-sidebar-light-separator-color-dark: $app-header-light-separator-color-dark;

$app-sidebar-light-scrollbar-color: $gray-200;
$app-sidebar-light-scrollbar-color-dark: $gray-200-dark;
$app-sidebar-light-scrollbar-color-hover: $gray-200;
$app-sidebar-light-scrollbar-color-hover-dark: $gray-200-dark;
$app-sidebar-light-menu-heading-color: #b5b5c3;
$app-sidebar-menu-heading-color-dark: $gray-500-dark;
$app-sidebar-menu-link-bg-color-active: $primary;
$app-sidebar-menu-link-bg-color-active-dark: $secondary;
$app-sidebar-menu-link-bg-color-hover: $gray-100;
$app-sidebar-menu-link-bg-color-hover-dark: $gray-200-dark;
$app-sidebar-header-menu-link-bg-color-active: #eaeef2;
$app-sidebar-header-menu-link-bg-color-active-dark: $gray-100-dark;


$app-sidebar-menu-link-text-color: $gray-500;
$app-sidebar-menu-link-icon-color: $gray-500;
$app-sidebar-menu-link-text-color-hover: darken($gray-500,50%);
$app-sidebar-menu-link-icon-color-hover: darken($gray-500,50%);
$app-sidebar-menu-link-text-color-active: $white;
$app-sidebar-menu-link-icon-color-active: $white; 
$app-sidebar-menu-link-text-color-dark:$secondary;
$app-sidebar-menu-link-icon-color-dark: $secondary;
$app-sidebar-menu-link-text-color-hover-dark: lighten($gray-500,50%);
$app-sidebar-menu-link-icon-color-hover-dark: lighten($gray-500,50%);
$app-sidebar-menu-link-text-color-active-dark: lighten($gray-500,100%);
$app-sidebar-menu-link-icon-color-active-dark: lighten($gray-500,100%);




// Sidebar dark
$app-sidebar-dark-bg-color: #1e1e2d;
$app-sidebar-dark-separator-color: #393945;
$app-sidebar-dark-scrollbar-color: $gray-300-dark;
$app-sidebar-dark-scrollbar-color-hover: $gray-300-dark;
$app-sidebar-dark-menu-heading-color: #646477;
$app-sidebar-dark-menu-link-bg-color-active: #e11f26;


// General
$app-general-root-font-size-desktop: 16px;
$app-general-root-font-size-tablet: 14px;
$app-general-root-font-size-mobile: 12px;

$h1-font-size: $font-size-base * 1.75; // 28px
$h2-font-size: $font-size-base * 1.5; // 24px
$h3-font-size: $font-size-base * 1.25; // 20px
$h4-font-size: $font-size-base * 1.125; // 18px
$h5-font-size: $font-size-base * 0.875; // 14px
$h6-font-size: $font-size-base * 0.75; // 12px
$h7-font-size: $font-size-base * 0.5; // 8px
$font-size-sm: $font-size-base * 0.875;

// $font-weight-lighter: lighter !default;
// $font-weight-light: 300 !default;
// $font-weight-normal: 400 !default;
// $font-weight-semibold: 500 !default;
// $font-weight-bold: 600 !default;
// $font-weight-bolder: 700 !default;

$font-sizes: (
	1: $h1-font-size,
	2: $h2-font-size,
	3: $h3-font-size,
	4: $h4-font-size,
	5: $h5-font-size,
	6: $h6-font-size,
	7: $h7-font-size,

	// 16px
	base: $font-size-base,
	// 100%
	fluid: 100%,

	// 32px
	2x: $font-size-base * 2,
	// 36px
	2qx: $font-size-base * 2.25,
	// 40px
	2hx: $font-size-base * 2.5,
	// 44px
	2tx: $font-size-base * 2.75,

	// 48px
	3x: $font-size-base * 3,
	// 52px
	3qx: $font-size-base * 3.25,
	// 56px
	3hx: $font-size-base * 3.5,
	// 60px
	3tx: $font-size-base * 3.75,

	// 64px
	4x: $font-size-base * 4,
	// 68px
	4qx: $font-size-base * 4.25,
	// 72px
	4hx: $font-size-base * 4.5,
	// 76px
	4tx: $font-size-base * 4.75,

	// 80px
	5x: $font-size-base * 5,
	// 84px
	5qx: $font-size-base * 5.25,
	// 88px
	5hx: $font-size-base * 5.5,
	// 92px
	5tx: $font-size-base * 5.75,
);

// Border Radiues
$border-radius-2xs: 0.25rem; //1
$border-radius-xs: 0.5rem; // 2
$border-radius-sm: 0.75rem; //3
$border-radius-base: 1rem; //4
$border-radius-lg: 1.25rem; //5
$border-radius-xl: 1.5rem; //6
$border-radius-2xl: 1.75rem;//7

$form-label-margin-bottom: 0.5rem;
$form-label-font-size: 1rem;
$form-label-font-weight: 500;
$form-label-color: $secondary;
// $form-label-color-dark: $gray-400-dark;

$input-placeholder-color: $gray-400;
$input-placeholder-color-dark: $gray-400-dark;
$input-solid-bg: $gray-100;
$input-solid-bg-dark: $gray-100-dark;
$input-solid-bg-focus: darken($gray-100, 2%);
$input-solid-bg-focus-dark: lighten($gray-100-dark, 2%);
$input-solid-placeholder-color: $input-placeholder-color;
$input-solid-placeholder-color-dark: $input-placeholder-color-dark;
$input-solid-color: $secondary;
$input-solid-color-dark: $secondary-dark;
$input-height: 3rem;
$input-width: 18.75rem;
$input-font-size: $font-size-base;
$input-border-width: 0;
$input-border-color: transparent;
$input-border-radius: $border-radius-sm;
$input-btn-font-size-sm: $font-size-sm;


$input-btn-padding-y: 1rem;
$input-btn-padding-y-sm: 0.8445rem;
$input-btn-padding-y-lg: 1.5rem;
$input-btn-padding-x: 3rem;
$input-btn-padding-x-sm: 2.5rem;
$btn-font-size: $font-size-base;
$btn-font-size-sm: $input-btn-font-size-sm;
$input-btn-line-height: 0;
$btn-border-radius: $border-radius-sm;
$btn-border-radius-sm: $border-radius-sm;

$btn-padding-y: $input-btn-padding-y;
$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x: $input-btn-padding-x;
$btn-padding-x-sm: $input-btn-padding-x-sm;


$app-container-padding-x: 1.75rem;
$app-container-padding-x-mobile: 1.5rem;
$app-header-base-height: 4.25rem;
$app-header-base-height-mobile: 3.75rem;


$menu-item-padding-y: 0rem;
$menu-item-padding-x: 0rem;
$menu-link-padding-y: 1rem;
$menu-link-padding-x: 1.25rem;
$app-sidebar-minimize-menu-link-padding-y: 1rem;
$app-sidebar-minimize-menu-link-padding-x: 1rem;
$menu-icon-size: 1.5rem;
$menu-icon-space: .75rem;

$menu-link-color-hover: darken($gray-500,50%);
$menu-link-color-here: darken($gray-500,50%);
$menu-link-color-show: darken($gray-500,50%);
$menu-link-color-active:darken($gray-500,100%);
$menu-link-bg-color-hover: $gray-100;
$menu-link-bg-color-active: $gray-100;


$table-cell-padding-y: 1.5rem;
$table-cell-padding-x: 1.25rem;
$table-cell-padding-y-sm: 1.25rem;
$table-cell-padding-x-sm: 1rem;

$modal-width: auto;

// $link-decoration: underline;


// $grid-columns: 12;
// $grid-gutter-width: 0;