@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?kxx9j5');
  src:  url('fonts/icomoon.eot?kxx9j5#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?kxx9j5') format('truetype'),
    url('fonts/icomoon.woff?kxx9j5') format('woff'),
    url('fonts/icomoon.svg?kxx9j5#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-image:before {
  content: "\e95b";
}
.icon-location:before {
  content: "\e95a";
}
.icon-gallery:before {
  content: "\e959";
}
.icon-info-circle-outline:before {
  content: "\e958";
}
.icon-no-entry:before {
  content: "\e957";
}
.icon-tick-circle-outline:before {
  content: "\e950";
}
.icon-trash-outline:before {
  content: "\e951";
}
.icon-profile-add:before {
  content: "\e952";
}
.icon-message-text:before {
  content: "\e953";
}
.icon-profile-outline:before {
  content: "\e954";
}
.icon-gallery-add:before {
  content: "\e955";
}
.icon-edit-outline:before {
  content: "\e956";
}
.icon-global:before {
  content: "\e928";
}
.icon-message-notif:before {
  content: "\e94d";
}
.icon-timer:before {
  content: "\e94e";
}
.icon-setting-2:before {
  content: "\e94f";
}
.icon-profile-warning .path1:before {
  content: "\e92a";
  color: rgb(160, 160, 160);
}
.icon-profile-warning .path2:before {
  content: "\e92b";
  margin-left: -0.9521484375em;
  color: rgb(160, 160, 160);
}
.icon-profile-warning .path3:before {
  content: "\e92c";
  margin-left: -0.9521484375em;
  color: rgb(255, 186, 0);
}
.icon-profile-warning .path4:before {
  content: "\e92d";
  margin-left: -0.9521484375em;
  color: rgb(255, 186, 0);
}
.icon-profile-warning .path5:before {
  content: "\e92e";
  margin-left: -0.9521484375em;
  color: rgb(255, 255, 255);
}
.icon-profile-warning .path6:before {
  content: "\e92f";
  margin-left: -0.9521484375em;
  color: rgb(255, 255, 255);
}
.icon-message-warning .path1:before {
  content: "\e930";
  color: rgb(160, 160, 160);
}
.icon-message-warning .path2:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(160, 160, 160);
}
.icon-message-warning .path3:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(160, 160, 160);
}
.icon-message-warning .path4:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(160, 160, 160);
}
.icon-message-warning .path5:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(255, 186, 0);
}
.icon-message-warning .path6:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(255, 186, 0);
}
.icon-message-warning .path7:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-message-warning .path8:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-setting-warning .path1:before {
  content: "\e938";
  color: rgb(160, 160, 160);
}
.icon-setting-warning .path2:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(160, 160, 160);
}
.icon-setting-warning .path3:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(255, 186, 0);
}
.icon-setting-warning .path4:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(255, 186, 0);
}
.icon-setting-warning .path5:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(255, 186, 0);
}
.icon-setting-warning .path6:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-setting-warning .path7:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-message-success .path1:before {
  content: "\e93f";
  color: rgb(160, 160, 160);
}
.icon-message-success .path2:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(160, 160, 160);
}
.icon-message-success .path3:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(160, 160, 160);
}
.icon-message-success .path4:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(160, 160, 160);
}
.icon-message-success .path5:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(0, 203, 150);
}
.icon-message-success .path6:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-setting-success .path1:before {
  content: "\e945";
  color: rgb(160, 160, 160);
}
.icon-setting-success .path2:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(160, 160, 160);
}
.icon-setting-success .path3:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(0, 203, 150);
}
.icon-setting-success .path4:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-profile-success .path1:before {
  content: "\e949";
  color: rgb(160, 160, 160);
}
.icon-profile-success .path2:before {
  content: "\e94a";
  margin-left: -0.9521484375em;
  color: rgb(160, 160, 160);
}
.icon-profile-success .path3:before {
  content: "\e94b";
  margin-left: -0.9521484375em;
  color: rgb(0, 203, 150);
}
.icon-profile-success .path4:before {
  content: "\e94c";
  margin-left: -0.9521484375em;
  color: rgb(255, 255, 255);
}
.icon-message-2:before {
  content: "\e929";
}
.icon-add:before {
  content: "\e927";
}
.icon-info-circle:before {
  content: "\e926";
}
.icon-arrow-left:before {
  content: "\e925";
}
.icon-microphone:before {
  content: "\e924";
}
.icon-profile-circle-add:before {
  content: "\e922";
}
.icon-Check:before {
  content: "\e91f";
}
.icon-profile-2:before {
  content: "\e91e";
}
.icon-system:before {
  content: "\e900";
}
.icon-add-circle:before {
  content: "\e901";
}
.icon-arrow-circle-fill:before {
  content: "\e902";
}
.icon-arrow-down:before {
  content: "\e903";
}
.icon-arrow-right:before {
  content: "\e920";
}
.icon-category:before {
  content: "\e904";
}
.icon-chevron-double-left:before {
  content: "\e905";
}
.icon-chevron-double-right:before {
  content: "\e906";
}
.icon-chevron-down:before {
  content: "\e907";
}
.icon-chevron-left:before {
  content: "\e921";
}
.icon-chevron-right:before {
  content: "\e923";
}
.icon-close:before {
  content: "\e908";
}
.icon-copy:before {
  content: "\e909";
}
.icon-dark-mode:before {
  content: "\e90a";
}
.icon-document-download:before {
  content: "\e90b";
}
.icon-edit:before {
  content: "\e90c";
}
.icon-folder-add:before {
  content: "\e90d";
}
.icon-hide:before {
  content: "\e90e";
}
.icon-logout:before {
  content: "\e90f";
}
.icon-message:before {
  content: "\e910";
}
.icon-notification:before {
  content: "\e911";
}
.icon-profile-circle:before {
  content: "\e912";
}
.icon-profile:before {
  content: "\e913";
}
.icon-search:before {
  content: "\e914";
}
.icon-send:before {
  content: "\e915";
}
.icon-setting:before {
  content: "\e916";
}
.icon-show:before {
  content: "\e917";
}
.icon-sun:before {
  content: "\e918";
}
.icon-tick-circle:before {
  content: "\e919";
}
.icon-trash:before {
  content: "\e91a";
}
.icon-user-cirlce-add:before {
  content: "\e91b";
}
.icon-video-circle:before {
  content: "\e91c";
}
.icon-video-square:before {
  content: "\e91d";
}
