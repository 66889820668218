#kt_app_root,
#root {
  height: 100%;
}

.backdrop-blur {
  background: rgba(10, 10, 10, 0.42);
  backdrop-filter: blur(7px);
  opacity: 1 !important;
}

.form-item-wrapper {
  row-gap: 2.25rem;
}

@media screen and (min-width: 768px) {
  .form-item-wrapper {
    row-gap: 1.25rem;
  }
}
